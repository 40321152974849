import React from 'react';
import { Menu, Container } from 'semantic-ui-react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Logo from '../graphics/logo_nobackground.js';

const NavbarComponent = (props) => {
  return (
    <NavbarMenu inverted attached="bottom">
      <Container>
        <Menu.Item to="/" as={Link} header>
          <Logo width="8em" height="2em" />
        </Menu.Item>
        {/* <Menu.Item>Map</Menu.Item>
      <Menu.Item>Stations</Menu.Item> */}
      </Container>
    </NavbarMenu>
  );
};

const NavbarMenu = styled(Menu)`
  font-size: 1.2em !important;
  margin-bottom: 0 !important;
  background: #115c97ff !important;
`;

export default NavbarComponent;
