// Production
export const url = 'https://tide-alerts.firebaseio.com/';
export const functionurl =
  'https://us-central1-tide-alerts.cloudfunctions.net/';

// // Dev
// export const url = 'https://tide-alerts-dev.firebaseio.com/';
// export const functionurl = 'https://us-central1-tide-alerts-dev.cloudfunctions.net/';
// export const query = ''

// //Local
// export const url = 'http://localhost:9000/';
// export const functionurl = 'http://localhost:5001/tide-alerts-dev/us-central1/';
// export const query = 'ns=tide-alerts-dev'
