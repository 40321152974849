import React, { Component } from 'react';
import { Button, Header } from 'semantic-ui-react';

class MonthPickerComponent extends Component {
  state = {
    month: null,
  };

  constructor(props) {
    super(props);

    const d = props.default ? props.default : new Date();
    d.setDate(1);
    d.setHours(0, 0, 0);
    this.state.month = d;
  }

  handleChange(direction) {
    const d = new Date(this.state.month);
    d.setMonth(d.getMonth() + direction);

    this.setState({ month: d });
    this.props.onChange(d);
  }

  render() {
    const { month } = this.state;
    const prev = new Date(month);
    const next = new Date(month);
    prev.setMonth(prev.getMonth() - 1);
    next.setMonth(next.getMonth() + 1);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          content={prev.toLocaleDateString('default', {
            month: 'short',
            year: 'numeric',
          })}
          icon="left arrow"
          labelPosition="left"
          onClick={() => this.handleChange(-1)}
          style={{ margin: 0 }}
        />
        <Header style={{ margin: 'auto', textAlign: 'center' }}>
          {month.toLocaleDateString('default', {
            month: 'short',
            year: 'numeric',
          })}
        </Header>
        <Button
          content={next.toLocaleDateString('default', {
            month: 'short',
            year: 'numeric',
          })}
          icon="right arrow"
          labelPosition="right"
          onClick={() => this.handleChange(1)}
          style={{ margin: 0 }}
        />
      </div>
    );
  }
}

export default MonthPickerComponent;
