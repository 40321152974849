import React, { Component } from 'react';

import {
  Map,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
  withLeaflet,
} from 'react-leaflet';
import { ReactLeafletSearch } from 'react-leaflet-search';
import { withRouter } from 'react-router-dom';
import { Button, Header, Icon } from 'semantic-ui-react';

const DEFAULT_VIEWPORT = {
  center: [0, 0],
  zoom: 2,
};

class ChannelMapComponent extends Component {
  state = {
    viewport: DEFAULT_VIEWPORT,
  };
  mapRef = React.createRef();
  groupRef = React.createRef();

  onViewportChanged = (viewport) => {
    this.setState({ viewport });
  };

  componentDidUpdate(oldProps) {
    if (oldProps.devices !== this.props.devices) {
      const map = this.mapRef.current.leafletElement;
      const group = this.groupRef.current.leafletElement;
      map.fitBounds(group.getBounds());
    }
  }

  render() {
    const { viewport } = this.state;
    const { devices, ...rest } = this.props;

    const SearchComponent = withLeaflet(ReactLeafletSearch);

    return (
      <Map
        id="map"
        onViewportChanged={this.onViewportChanged}
        viewport={viewport}
        animate={true}
        ref={this.mapRef}
        {...rest}
      >
        <TileLayer
          attribution='<a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />
        <SearchComponent
          position="topleft"
          provider="OpenStreetMap"
          showMarker={true}
          showPopup={false}
        />
        <FeatureGroup ref={this.groupRef}>
          {devices?.map((device) => {
            const {
              metadata: { latitude, longitude, station_id },
            } = device;
            if (!latitude || !longitude) return null;
            return (
              <Marker position={[latitude, longitude]} key={station_id}>
                <Popup>
                  <div>
                    <Header size="medium" as="span">
                      <b>Station {station_id}</b>
                    </Header>
                    <div>
                      <Icon name="map marker alternate" />
                      {latitude.toFixed(2)}, {longitude.toFixed(2)}
                    </div>
                    <br />
                    <Button
                      fluid
                      onClick={() =>
                        this.props.history.push(`/stations/${station_id}`)
                      }
                    >
                      View Station
                    </Button>
                  </div>
                </Popup>
              </Marker>
            );
          })}
        </FeatureGroup>
      </Map>
    );
  }
}

export default withRouter(ChannelMapComponent);
