import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePageController from './controllers/HomePageController';
import StationPageController from './controllers/StationPageController';
import NavbarComponent from './NavbarComponent';
import FooterComponent from './FooterComponent';
import PageNotFoundComponent from './pages/PageNotFoundComponent';

const Routes = (props) => (
  <BrowserRouter>
    <NavbarComponent />
    <Switch>
      <Route path="/stations/:stationId" component={StationPageController} />
      <Route exact path="/" component={HomePageController} />
      <Route component={PageNotFoundComponent} />
    </Switch>
    <FooterComponent />
  </BrowserRouter>
);

export default Routes;
