import React from 'react';
import { Segment, Container } from 'semantic-ui-react';
import styled from 'styled-components';

const FooterComponent = ({ yearPublished = 2018, ...rest }) => {
  const year = new Date().getFullYear();
  return (
    <StyledFooter inverted attached="top" {...rest}>
      <Container>
        © {year === yearPublished ? year : `${yearPublished}-${year}`} Sentient
        Things
      </Container>
    </StyledFooter>
  );
};

const StyledFooter = styled(Segment)`
  margin-top: 0 !important;
  background: #115c97ff !important;
`;

export default FooterComponent;
