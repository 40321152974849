import React from 'react';
import moment from 'moment';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const TimeSeriesChartComponent = (props) => {
  const options = {
    chart: {
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      height: props.height,
    },
    title: {
      text: props.title,
      useHTML: true,
    },
    xAxis: {
      type: 'datetime',
      plotLines: [
        {
          value: new Date(),
          label: 'Current time',
          dashStyle: 'dash',
        },
      ],
    },
    yAxis: {
      title: {
        text: 'Level (relative to MLLW)',
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      // enabled: false,
    },
    plotOptions: {},
    time: {
      useUTC: false,
      moment: moment,
    },
    series: [
      {
        type: 'line',
        name: 'Observed',
        data: props.data.measurements,
        tooltip: {
          valueSuffix: props.unit,
        },
      },
      {
        type: 'line',
        name: 'Predicted',
        data: props.data.forecast,
        tooltip: {
          valueSuffix: props.unit,
        },
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default TimeSeriesChartComponent;
