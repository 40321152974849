import React, { Component } from 'react';
import TideChartComponent from '../charts/TideChartComponent';
import {
  Container,
  Segment,
  Dropdown,
  Select,
  Tab,
  Grid,
  Item,
  Header,
} from 'semantic-ui-react';
import TideTableComponent from '../station/TideTableComponent';
import MonthPickerComponent from '../MonthPickerComponent';
import MetadataTableComponent from '../station/MetadataTableComponent';
import StatisticsTableComponent from '../station/StatisticsTableComponent';
import VitalsTableComponent from '../station/VitalsTableComponent';
import DailyTidesTableComponent from '../station/DailyTidesTableComponent';
import LatestReadingStatComponent from '../station/LatestReadingStatComponent';
import WindChartComponent from '../charts/WindChartComponent';
import LineChartComponent from '../charts/LineChartComponent';
import { unitStandards } from '../../reducers/unitSlice';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MultilineChartComponent from '../charts/MultilineChartComponent';
// import CalendarThresholdComponent from '../station/CalendarThresholdComponent';
import TideCalendarComponent from '../station/TideCalendarComponent';

class StationPageComponent extends Component {
  state = {
    month: new Date(),
  };

  downloadAsPDF = () => {
    const doc = new jsPDF();
    const dateString = this.state.month.toLocaleDateString('default', {
      month: 'short',
      year: 'numeric',
    });

    doc.autoTable({ html: '#tide-table' });
    doc.save(`${dateString} Tide Table.pdf`);
  };

  componentDidMount() {
    const start = new Date();
    start.setHours(0, 0, 0);
    // Set to longer i.e. 30 for local dev
    start.setDate(start.getDate() - 1);
    const end = new Date();
    end.setHours(0, 0, 0);
    end.setDate(end.getDate() + 2);

    // const monthStart = new Date(this.state.month);
    // monthStart.setDate(0);
    // const monthEnd = new Date(this.state.month);
    // monthEnd.setMonth(monthEnd.getMonth() + 1);
    // monthEnd.setDate(0);

    this.props.getStationMetadata();
    this.props.getStationDatum();
    this.props.getStationStatistics();
    this.props.getStationMeasurements(start, end);
    this.props.getStationForecast(start, end);
    this.props.getStationForecastHighsAndLows();
    this.props.getStationMetData(start, end);
    this.props.getStationTideThreshold();
  }

  componentDidUpdate(oldProps, oldState) {
    // if (oldState.month !== this.state.month) {
    //   const monthStart = new Date(this.state.month);
    //   monthStart.setDate(0);
    //   const monthEnd = new Date(this.state.month);
    //   monthEnd.setMonth(monthEnd.getMonth() + 1);
    //   monthEnd.setDate(0);
    //   this.props.getStationForecastHighsAndLows(monthStart, monthEnd);
    // }

    if (this.props.metadata !== oldProps.metadata) {
      this.props.getStationVitals(this.props.metadata.device_id);
    }
    if (
      (this.props.units !== oldProps.units ||
        this.props.threshold !== oldProps.threshold) &&
      this.props.threshold !== null
    ) {
      const unit = this.props.units.standard;
      this.props.getStationTideCalendar(
        unit,
        this.props.threshold[unit],
        this.props.threshold.isLow
      );
    }
  }

  render() {
    const {
      tidesToday,
      tidesTomorrow,
      latestMeasurement,
      latestMetData,
      measurements,
      met,
      forecast,
      tableData,
      stationId,
      metadata,
      units,
      setUnit,
      statistics,
      vitals,
      tideCalendar,
      threshold,
    } = this.props;
    const { month } = this.state;
    let nextTideTodayIndex;
    let nextTideTomorrowIndex;
    let filteredTableData;

    if (tidesToday) {
      tidesToday.sort((a, b) => a.time - b.time);
      tidesTomorrow.sort((a, b) => a.time - b.time);

      const time = new Date().getTime() / 1000;
      for (let i = 0; i < tidesToday.length; i++) {
        if (
          tidesToday[i].time > time &&
          new Date(tidesToday[i].time * 1000).getDate() === new Date().getDate()
        ) {
          nextTideTodayIndex = i;
          break;
        }
      }
      for (let i = 0; i < tidesTomorrow.length; i++) {
        if (tidesTomorrow[i].time > time && !nextTideTodayIndex) {
          nextTideTomorrowIndex = i;
          break;
        }
      }
    }

    if (tableData) {
      filteredTableData = Object.values(tableData).filter(
        ({ date }) =>
          date.getMonth() === month.getMonth() &&
          date.getYear() === month.getYear()
      );
    }

    const filteredMetData = {};
    if (met) {
      filteredMetData.windDir = met.windDir.filter(
        (item) => new Date(item.x).getMinutes() % 5 === 0
      );
      filteredMetData.windSpeed = met.windSpeed.filter(
        (item) => new Date(item[0]).getMinutes() % 5 === 0
      );
      filteredMetData.gust = met.gust.filter(
        (item) => new Date(item[0]).getMinutes() % 5 === 0
      );
    }

    return (
      <Container style={{ flexGrow: 1 }}>
        {stationId && metadata && (
          <div style={{ padding: '1.5em 0' }}>
            <Item.Group>
              <Item>
                <Item.Image
                  src={`https://github.com/mawrob/images/blob/master/${stationId}.jpg?raw=true`}
                  size="small"
                />
                <Item.Content>
                  <Item.Header>
                    <h1>Station {stationId}</h1>
                  </Item.Header>
                  <Item.Meta>{metadata.name}</Item.Meta>
                  <Item.Description>
                    <Select
                      options={[
                        {
                          key: unitStandards.metric,
                          value: unitStandards.metric,
                          text: 'Metric units',
                        },
                        {
                          key: unitStandards.us,
                          value: unitStandards.us,
                          text: 'US units',
                        },
                      ]}
                      value={units.standard}
                      onChange={(e, d) => setUnit(d.value)}
                    />
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>
        )}

        <Grid columns={'equal'} stackable>
          <Grid.Column>
            {metadata && <MetadataTableComponent metadata={metadata} />}
          </Grid.Column>
          <Grid.Column>
            <Tab
              // menu={{ text: true }}
              panes={[
                {
                  menuItem: 'Statistics',
                  render: () => (
                    <Tab.Pane as="div">
                      <StatisticsTableComponent
                        statistics={statistics}
                        unit={units.length.format}
                        attached="bottom"
                      />
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: 'Vitals',
                  render: () => (
                    <Tab.Pane as="div">
                      <VitalsTableComponent vitals={vitals} attached="bottom" />
                    </Tab.Pane>
                  ),
                },
              ]}
            />
          </Grid.Column>
        </Grid>

        <Grid columns="equal">
          <Grid.Column only="tablet computer">
            {tidesToday?.length > 0 && (
              <DailyTidesTableComponent
                title="Tides Today"
                tides={tidesToday}
                nextTideIndex={nextTideTodayIndex}
                unit={units.length.format}
              />
            )}
          </Grid.Column>
          <Grid.Column only="tablet computer">
            {tidesTomorrow?.length > 0 && (
              <DailyTidesTableComponent
                title="Tides Tomorrow"
                tides={tidesTomorrow}
                nextTideIndex={nextTideTomorrowIndex}
                unit={units.length.format}
              />
            )}
          </Grid.Column>
          <Grid.Column only="mobile">
            {tidesToday?.length > 0 && tidesTomorrow?.length > 0 && (
              <Tab
                panes={[
                  {
                    menuItem: 'Tides Today',
                    render: () => (
                      <Tab.Pane as="div">
                        <DailyTidesTableComponent
                          headless
                          tides={tidesToday}
                          nextTideIndex={nextTideTodayIndex}
                          unit={units.length.format}
                        />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Tides Tomorrow',
                    render: () => (
                      <Tab.Pane as="div">
                        <DailyTidesTableComponent
                          headless
                          tides={tidesTomorrow}
                          nextTideIndex={nextTideTomorrowIndex}
                          unit={units.length.format}
                        />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            )}
          </Grid.Column>
        </Grid>

        <Grid stackable columns="equal" divided="vertically">
          <Grid.Row style={{ marginTop: '1rem' }}>
            <Grid.Column
              largeScreen={4}
              computer={5}
              tablet={6}
              textAlign="right"
            >
              {latestMeasurement && (
                <LatestReadingStatComponent
                  title="Observed Level"
                  value={latestMeasurement.value}
                  unit={units.length.format}
                  time={latestMeasurement.time}
                />
              )}
            </Grid.Column>
            <Grid.Column>
              <TideChartComponent
                data={{ measurements, forecast }}
                height={300}
                unit={units.length.format}
                title={`Water Level`}
              />
            </Grid.Column>
          </Grid.Row>
          {latestMetData && (
            <>
              {!!latestMetData.wind_speed && (
                <Grid.Row>
                  <Grid.Column
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    textAlign="right"
                  >
                    {latestMetData && (
                      <LatestReadingStatComponent
                        title="Wind Speed"
                        value={latestMetData.wind_speed}
                        unit={units.speed.format}
                        time={latestMetData.time}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <WindChartComponent
                      data={{
                        windDir: filteredMetData?.windDir,
                        windSpeed: filteredMetData?.windSpeed,
                        gust: filteredMetData?.gust,
                      }}
                      height={300}
                      title={`Wind`}
                      unit={units.speed.format}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {(latestMetData.water_temp || latestMetData.air_temp) && (
                <Grid.Row>
                  <Grid.Column
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    textAlign="right"
                  >
                    {latestMetData && (
                      <>
                        {!!latestMetData.water_temp && (
                          <LatestReadingStatComponent
                            title="Water Temperature"
                            value={latestMetData.water_temp}
                            unit={units.temperature.format}
                            time={latestMetData.time}
                          />
                        )}
                        {!!latestMetData.air_temp && (
                          <LatestReadingStatComponent
                            title="Air Temperature"
                            value={latestMetData.air_temp}
                            unit={units.temperature.format}
                            time={latestMetData.time}
                          />
                        )}
                      </>
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <MultilineChartComponent
                      data={[met?.waterTemp, met?.airTemp]}
                      height={350}
                      title="Temperature"
                      yAxis="Temperature"
                      dataLabel={['Water Temperature', 'Air Temperature']}
                      units={[
                        units.temperature.format,
                        units.temperature.format,
                      ]}
                      lines={2}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {!!latestMetData.humidity && (
                <Grid.Row>
                  <Grid.Column
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    textAlign="right"
                  >
                    {latestMetData && (
                      <LatestReadingStatComponent
                        title="Humidity"
                        value={latestMetData.humidity}
                        unit="%"
                        time={latestMetData.time}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <LineChartComponent
                      data={met?.humidity}
                      height={300}
                      title="Humidity"
                      yAxis="Humidity"
                      dataLabel="Humidity"
                      unit="%"
                      yCeiling={100}
                      yFloor={0}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {!!latestMetData.pressure && (
                <Grid.Row>
                  <Grid.Column
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    textAlign="right"
                  >
                    {latestMetData && (
                      <LatestReadingStatComponent
                        title="Pressure"
                        value={latestMetData.pressure}
                        unit={units.pressure.format}
                        time={latestMetData.time}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <LineChartComponent
                      data={met?.pressure}
                      height={300}
                      title="Atmospheric Pressure"
                      yAxis="Atmospheric Pressure"
                      dataLabel="Atmospheric Pressure"
                      unit={units.pressure.format}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            </>
          )}
        </Grid>

        <Tab
          panes={[
            {
              menuItem: 'Tide Table',
              render: () => (
                <Tab.Pane
                  style={{
                    height: '85vh',
                    maxHeight: '1550px',
                    overflowY: 'scroll',
                  }}
                  loading={!filteredTableData}
                >
                  <Segment vertical style={{ paddingTop: 0 }}>
                    <MonthPickerComponent
                      onChange={this.handleMonthSelection}
                    />
                    <Dropdown
                      text="Download"
                      style={{
                        padding: '0.5em',
                      }}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          text="PDF"
                          onClick={this.downloadAsPDF}
                        />
                        {/* <Dropdown.Item text="CSV" /> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    {filteredTableData?.length > 0 ? (
                      <TideTableComponent
                        unit={units.length.format}
                        data={filteredTableData}
                        id="tide-table"
                      />
                    ) : (
                      <Header textAlign="center">No data available</Header>
                    )}
                  </Segment>
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Tide Calendar',
              render: () => (
                <Tab.Pane
                  style={{
                    height: '85vh',
                    maxHeight: '1550px',
                    overflowY: 'scroll',
                  }}
                  loading={tideCalendar === undefined && threshold !== null}
                  disabled={threshold === null}
                >
                  <TideCalendarComponent
                    data={tideCalendar}
                    style={{ height: '100%' }}
                  />
                  {/* <CalendarThresholdComponent
                    stationId={stationId}
                    units={units.standard}
                  /> */}
                </Tab.Pane>
              ),
            },
          ]}
        />
        <br />
      </Container>
    );
  }

  handleMonthSelection = (month) => {
    this.setState({ month });
  };
}

/* {metadata && (
              <Map
                id="map"
                viewport={{
                  center: [metadata.latitude, metadata.longitude],
                  zoom: 15,
                }}
                zoomControl={false}
                dragging={false}
                doubleClickZoom={false}
                keyboard={false}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='<a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
                  url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                />
                <Marker position={[metadata.latitude, metadata.longitude]}>
                  <Popup>
                    <div>
                      <Header size="medium" as="span">
                        <b>Station {stationId}</b>
                      </Header>
                      <div>
                        <Icon name="map marker alternate" />
                        {metadata.latitude.toFixed(2)},{' '}
                        {metadata.longitude.toFixed(2)}
                      </div>
                    </div>
                  </Popup>
                </Marker>
              </Map>
            )} */

export default StationPageComponent;
