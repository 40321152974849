import { fetchAllStations } from '../../reducers/stationSlice';
import { connect } from 'react-redux';
import HomePageComponent from '../pages/HomePageComponent';

const mapStateToProps = (state, ownProps) => {
  return {
    devices: state.stations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllStations: () => dispatch(fetchAllStations()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);
