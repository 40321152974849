import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

const PageNotFoundComponent = (props) => (
  <StyledPageNotFound size="huge">
    <Header.Content style={{ fontSize: '3em', lineHeight: 'normal' }}>
      404
    </Header.Content>
    <br />
    <Header.Content>Page not found :(</Header.Content>
    <Header.Subheader>
      <Link to="/">Back to Home</Link>
    </Header.Subheader>
  </StyledPageNotFound>
);

const StyledPageNotFound = styled(Header)`
  flex-grow: 1;
  text-align: center;
`;

export default PageNotFoundComponent;
