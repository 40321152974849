import React, { Component } from 'react';
import styled from 'styled-components';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import Routes from './Routes';

class App extends Component {
  render() {
    return (
      <StyledApp>
        <Routes />
      </StyledApp>
    );
  }
}

const StyledApp = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default App;
