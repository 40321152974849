import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllStations,
  getStationMeasurements,
  getStationMetadata,
  getStationForecast,
  getStationForecastHighsAndLows,
  getStationStatistics,
  getStationVitals,
  getStationMetData,
  getStationDatum,
  getStationTideCalendar,
  getStationTideThreshold as getStationTideCalendarThreshold,
} from '../services/TideStationService';
import { unitStandards } from './unitSlice';

export const fetchAllStations = createAsyncThunk(
  'stations/fetch/all',
  async () => {
    const response = await getAllStations();
    return response;
  }
);

export const fetchStationMeasurements = createAsyncThunk(
  'stations/fetch/measurements',
  async ({ stationId, start, end }, thunkApi) => {
    const response = await getStationMeasurements(stationId, start, end);
    return response;
  }
);

export const fetchStationMetData = createAsyncThunk(
  'stations/fetch/meteorological',
  async ({ stationId, start, end }, thunkApi) => {
    const response = await getStationMetData(stationId, start, end);
    return response;
  }
);

export const fetchStationForecast = createAsyncThunk(
  'stations/fetch/forecast',
  async ({ stationId, start, end }, thunkApi) => {
    const response = await getStationForecast(stationId, start, end);
    return response;
  }
);

export const fetchStationForecastHighsAndLows = createAsyncThunk(
  'stations/fetch/forecastHighsAndLows',
  async ({ stationId, start, end }, thunkApi) => {
    const response = await getStationForecastHighsAndLows(
      stationId,
      start,
      end
    );
    return response;
  }
);

export const fetchStationDatum = createAsyncThunk(
  'stations/fetch/datum',
  async (stationId, thunkApi) => {
    const response = await getStationDatum(stationId);
    return response;
  }
);

export const fetchStationMetadata = createAsyncThunk(
  'stations/fetch/metadata',
  async (stationId, thunkApi) => {
    const response = await getStationMetadata(stationId);
    return response;
  }
);

export const fetchStationStatistics = createAsyncThunk(
  'stations/fetch/statistics',
  async (stationId, thunkApi) => {
    const response = await getStationStatistics(stationId);
    return response;
  }
);

export const fetchStationVitals = createAsyncThunk(
  'stations/fetch/vitals',
  async ({ deviceId }, thunkApi) => {
    const response = await getStationVitals(deviceId);
    return response;
  }
);

export const fetchStationTideCalendar = createAsyncThunk(
  'stations/fetch/tideCalendar',
  async ({ stationId, unit, isLow, threshold }, thunkApi) => {
    const response = await getStationTideCalendar(
      stationId,
      unit,
      isLow,
      threshold
    );
    return response;
  }
);

export const fetchStationTideCalendarThreshold = createAsyncThunk(
  'stations/fetch/tideCalendarThreshold',
  async ({ stationId }, thunkApi) => {
    const response = await getStationTideCalendarThreshold(stationId);
    return response;
  }
);

const stationListSlice = createSlice({
  name: 'stations',
  initialState: {},
  reducers: {},
  extraReducers: {
    [fetchAllStations.fulfilled]: (state, action) => {
      for (const [stationId, metadata] of Object.entries(action.payload)) {
        if (state[stationId]) {
          state[stationId] = { ...state[stationId], metadata };
        } else {
          state[stationId] = { metadata };
        }
      }
    },
    [fetchAllStations.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationMeasurements.fulfilled]: (state, action) => {
      const { stationId } = action.meta.arg;
      if (state[stationId]) {
        state[stationId].measurements = action.payload;
      } else {
        state[stationId] = { measurements: action.payload };
      }
    },
    [fetchStationMeasurements.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationMetData.fulfilled]: (state, action) => {
      const { stationId } = action.meta.arg;
      if (state[stationId]) {
        state[stationId].met = action.payload;
      } else {
        state[stationId] = { met: action.payload };
      }
    },
    [fetchStationMetData.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationForecast.fulfilled]: (state, action) => {
      const { stationId } = action.meta.arg;
      if (!action.payload || Object.keys(action.payload).length === 0) {
        return state;
      }

      if (state[stationId]) {
        state[stationId].forecast = action.payload;
      } else {
        state[stationId] = { forecast: action.payload };
      }
    },
    [fetchStationForecast.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationForecastHighsAndLows.fulfilled]: (state, action) => {
      const { stationId } = action.meta.arg;
      if (Object.keys(action.payload).length === 0) {
        return state;
      }

      if (state[stationId]) {
        const station = state[stationId];
        if (station.forecastHighs) {
          station.forecastHighs = {
            ...station.forecastHighs,
            ...action.payload.forecastHighs,
          };
        } else {
          station.forecastHighs = action.payload.forecastHighs;
        }
        if (station.forecastLows) {
          station.forecastLows = {
            ...station.forecastLows,
            ...action.payload.forecastLows,
          };
        } else {
          station.forecastLows = action.payload.forecastLows;
        }
      } else {
        state[stationId] = { ...action.payload };
      }
    },
    [fetchStationForecastHighsAndLows.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationDatum.fulfilled]: (state, action) => {
      const stationId = action.meta.arg;
      if (state[stationId]) {
        state[stationId].datum = action.payload;
      } else {
        state[stationId] = { datum: action.payload };
      }
    },
    [fetchStationMetadata.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationMetadata.fulfilled]: (state, action) => {
      const stationId = action.meta.arg;
      if (state[stationId]) {
        state[stationId].metadata = action.payload;
      } else {
        state[stationId] = { metadata: action.payload };
      }
    },
    [fetchStationMetadata.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationStatistics.fulfilled]: (state, action) => {
      const stationId = action.meta.arg;
      if (state[stationId]) {
        state[stationId].statistics = action.payload;
      } else {
        state[stationId] = { statistics: action.payload };
      }
    },
    [fetchStationStatistics.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationVitals.fulfilled]: (state, action) => {
      const stationId = action.meta.arg.stationId;
      if (state[stationId]) {
        state[stationId].vitals = action.payload;
      } else {
        state[stationId] = { vitals: action.payload };
      }
    },
    [fetchStationVitals.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationTideCalendar.fulfilled]: (state, action) => {
      const { stationId, unit } = action.meta.arg;
      if (state[stationId]['tideCalendar']) {
        state[stationId]['tideCalendar'][unit] = action.payload;
      } else {
        state[stationId]['tideCalendar'] = { [unit]: action.payload };
      }
    },
    [fetchStationTideCalendar.rejected]: (state, action) => {
      console.error(state, action);
    },

    [fetchStationTideCalendarThreshold.fulfilled]: (state, action) => {
      const { stationId } = action.meta.arg;

      let threshold;
      if (action.payload === null) {
        threshold = null;
      } else {
        const defaultUnit = action.payload.unit;
        const defaultThreshold = action.payload.level;
        const isLow = action.payload.low;

        threshold = { isLow };
        if (defaultUnit === unitStandards.metric) {
          threshold[unitStandards.metric] = defaultThreshold;
          threshold[unitStandards.us] = defaultThreshold * 3.28084;
        } else if (defaultUnit === unitStandards.us) {
          threshold[unitStandards.metric] = defaultThreshold / 3.28084;
          threshold[unitStandards.us] = defaultThreshold;
        }
      }

      if (!state[stationId]) state[stationId] = {};
      if (state[stationId]['tideCalendar']) {
        state[stationId]['tideCalendar'].threshold = threshold;
      } else {
        state[stationId]['tideCalendar'] = { threshold };
      }
    },
    [fetchStationTideCalendarThreshold.rejected]: (state, action) => {
      console.error(state, action);
    },
  },
});

export default stationListSlice.reducer;
