import React, { Component } from 'react';
import ChannelMapComponent from '../ChannelMapComponent';

class HomePageComponent extends Component {
  state = {};

  async componentDidMount() {
    this.props.getAllStations();
  }

  render() {
    const { devices } = this.props;
    const listedDevices = Object.values(devices)?.filter(
      ({ metadata }) => metadata.listed
    );

    return (
      <>
        <ChannelMapComponent devices={listedDevices} style={{ flexGrow: 1 }} />
      </>
    );
  }
}

export default HomePageComponent;
