import { createSlice } from '@reduxjs/toolkit';

export const unitStandards = {
  us: 'us',
  metric: 'metric',
};

const unitTypes = {
  [unitStandards.us]: {
    standard: unitStandards.us,
    temperature: { value: 'F', format: '°F' },
    length: { value: 'ft', format: 'ft.' },
    speed: { value: 'knot', format: 'kn.' },
    pressure: { value: 'hPa', format: 'mbar' },
  },
  [unitStandards.metric]: {
    standard: unitStandards.metric,
    temperature: { value: 'C', format: '°C' },
    length: { value: 'm', format: 'm' },
    speed: { value: 'm/s', format: 'm/s' },
    pressure: { value: 'hPa', format: 'hPa' },
  },
};

export const units = createSlice({
  name: 'units',
  initialState: window.localStorage.getItem('unit-standard')
    ? unitTypes[window.localStorage.getItem('unit-standard')]
    : unitTypes[unitStandards.us],
  reducers: {
    setStandard: (state, action) => {
      state = unitTypes[action.payload];
      window.localStorage.setItem('unit-standard', action.payload);
      return state;
    },
  },
  extraReducers: {},
});

export default units.reducer;
